import { Box, Button, Center, Flex, Heading, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { type FunctionComponent, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WireGuardInstanceTypeSelection } from './WireGuardInstanceTypeSelection';
import { AccountDataField } from 'components';
import { CancelSubscriptionModal } from 'components/CancelSubscriptionModal';
import { formatToDateTimeString } from 'utils/dateUtils';
import {
  ShopProductSku,
  SubscriptionState,
  UserGroup,
  WireGuardInstanceType,
  type WireGuardSubscription,
  useUpdateSubscriptionMutation,
  useWireGuardQuery,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';
import { routes } from 'utils/routes';

// FIXME: remove/reuse on switch to new payment provider
// const isCreditCardChangeActive = process.env.REACT_APP_TOGGLE_UNZER_CREDIT_CARD_CHANGE === 'true';

interface WireGuardSubscriptionSectionProps {
  subscription?: WireGuardSubscription | null;
}

export const WireGuardSubscriptionSection: FunctionComponent<WireGuardSubscriptionSectionProps> = ({
  subscription,
}) => {
  const { t } = useTranslation();
  const { readonlyProps } = useAuthUtils();
  const { hasRequiredRole } = useAuthUserContext();
  const { setOrderItems, prefetchShopCheckoutPageData } = useShopCheckoutContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const { mutateAsync, isPending } = useUpdateSubscriptionMutation();
  const {
    isOpen: isReactivationOpen,
    onOpen: onReactivationOpen,
    onClose: onReactivationClose,
  } = useDisclosure();
  // FIXME: remove/reuse on switch to new payment provider
  // const {
  //   isOpen: isUpdateCreditCardOpen,
  //   onOpen: onUpdateCreditCardOpen,
  //   onClose: onUpdateCreditCardClose,
  // } = useDisclosure();

  const [instanceType, setInstanceType] = useState<WireGuardInstanceType>(
    WireGuardInstanceType.Small,
  );

  const isSubscriptionActive = useMemo(
    () => subscription?.state === SubscriptionState.Active && !subscription.endDate,
    [subscription],
  );

  const instanceTypeSku = useMemo(() => {
    switch (instanceType) {
      case WireGuardInstanceType.Small:
        return ShopProductSku.WireguardBase_1m;
      case WireGuardInstanceType.Xlarge:
        return ShopProductSku.WireguardXl_1m;
      default:
        throw new Error(
          `Could not find a shop product that matches WireGuard instance type: ${instanceType}`,
        );
    }
  }, [instanceType]);

  const handleReactivation = useCallback(
    async (subscriptionId: string) => {
      try {
        await mutateAsync({
          subscriptionId,
          endDate: '', // empty string means reactivation
        });
        await queryClient.refetchQueries({ queryKey: useWireGuardQuery.getKey() });

        successToast({ title: t('subscription.reactivate.success') });
      } catch (error) {
        if (
          error instanceof Error &&
          error.message.startsWith('Maximum number of unpaid suborders reached,')
        ) {
          errorToast({
            title: t('subscription.unpaidSubordersError'),
            description: t('subscription.unpaidSubordersErrorDescription'),
          });
        } else {
          errorToast({ title: t('subscription.reactivate.error') });
        }
      }
    },
    [mutateAsync, queryClient, successToast, errorToast, t],
  );

  const prefetchCheckoutData = useCallback(() => {
    prefetchShopCheckoutPageData(instanceTypeSku);
  }, [instanceTypeSku, prefetchShopCheckoutPageData]);

  return (
    <VStack alignItems="normal">
      <Heading fontSize="lg">{t('config.wireGuard.subscription.sectionTitle')}</Heading>
      {subscription ? (
        <Box>
          <AccountDataField
            title={t('common.state')}
            data={t(
              `config.wireGuard.subscription.states.${
                isSubscriptionActive ? subscription.state : 'canceled'
              }`,
            )}
            mx="0"
          />
          <AccountDataField
            title={t('config.wireGuard.instanceType')}
            data={t(`config.wireGuard.instanceTypes.${subscription.instanceType}`)}
            mx="0"
          />
          {subscription.endDate && (
            <AccountDataField
              title={t('config.wireGuard.subscription.endDate')}
              data={formatToDateTimeString(subscription.endDate, true)}
              mx="0"
            />
          )}
          {isSubscriptionActive && (
            <CancelSubscriptionModal
              subscriptionId={subscription.id}
              isOpen={isReactivationOpen}
              onClose={onReactivationClose}
              onSuccessAction={async () =>
                queryClient.refetchQueries({ queryKey: useWireGuardQuery.getKey() })
              }
            />
          )}
          {/*
          FIXME: remove/reuse on switch to new payment provider
          {isUpdateCreditCardOpen && isCreditCardChangeActive && (
            <UpdateCreditCardModal
              isOpen={isUpdateCreditCardOpen}
              onClose={onUpdateCreditCardClose}
              subscriptionId={subscription.id}
            />
          )} */}
          <Flex mt="4" justifyContent="space-evenly">
            <Button
              {...readonlyProps}
              isLoading={isPending}
              isDisabled={!hasRequiredRole(UserGroup.CompanyAdmin) || readonlyProps.isDisabled}
              onClick={async () => {
                if (isSubscriptionActive) {
                  onReactivationOpen();
                } else {
                  handleReactivation(subscription.id);
                }
              }}
            >
              {t(
                isSubscriptionActive
                  ? 'subscription.cancel.button'
                  : `subscription.reactivate.button${isPending ? 'Loading' : ''}`,
              )}
            </Button>
            {/*
            FIXME: remove/reuse on switch to new payment provider
            <Button
              onClick={() => onUpdateCreditCardOpen()}
              isDisabled={
                !hasRequiredRole(UserGroup.CompanyAdmin) ||
                !isCreditCardChangeActive ||
                readonlyProps.isDisabled
              }
              title={
                !isCreditCardChangeActive
                  ? t('subscription.changePaymentMethodDisabled')
                  : readonlyProps.title
              }
            >
              {t('subscription.changePaymentMethod')}
            </Button> */}
          </Flex>
        </Box>
      ) : (
        <>
          <Text>{t('config.wireGuard.subscription.noSubscriptionInfo')}</Text>

          <WireGuardInstanceTypeSelection
            wireGuardInstance={instanceType}
            setWireGuardInstance={setInstanceType}
            my="4"
          />

          <Text>{t('config.wireGuard.instanceTypes.description')}</Text>

          <Text>{t('config.wireGuard.subscription.getSubscriptionInfo')}</Text>

          <Center>
            <Button
              mt="4"
              onMouseEnter={prefetchCheckoutData}
              onFocus={prefetchCheckoutData}
              onClick={() => {
                prefetchShopCheckoutPageData(instanceTypeSku);
                setOrderItems([{ sku: instanceTypeSku, quantity: 1 }]);
                navigate(routes.shopCheckout);
              }}
              {...readonlyProps}
            >
              {t('config.wireGuard.subscription.goToCheckout')}
            </Button>
          </Center>
        </>
      )}
    </VStack>
  );
};
